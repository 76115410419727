import React, { ReactElement } from "react"
import { FreeAccount, GetStarted } from "../components/HomePage/Banner"
import NewsAndUpdates from "../components/HomePage/NewsAndUpdates"
import Subscribe from "../components/HomePage/Subscribe"
import Seo from "../components/seo"
import DefaultLayout from "../templates/DefaultLayout"

export default function ThankYou(): ReactElement {
  return (
    <DefaultLayout>
      <Seo title="Thank you" />
      <div className="min-h-[70vh] flex items-center justify-center flex-col text-br-primary-blue text-center px-4">
        <h1 className="text-3xl md:text-5xl font-bold pb-4">Thank you!</h1>

        <p className="text-lg md:text-2xl mt-5 max-w-[600px] pb-6">
          We will review your message and get back to you as soon as we can.
        </p>
      </div>
      <Subscribe dark title="Stay up to Date" />
      <div className="py-14 flex items-center justify-center flex-col text-br-primary-blue text-center px-4">
        <h2 className="text-2xl md:text-4xl  pb-4">Why Wait?</h2>

        <p className="text-lg md:text-2xl mt-5 max-w-[600px] pb-6">
          With built in tutorials, <br /> you can make and account and get
          started today!
        </p>
        <GetStarted />
        <FreeAccount />
      </div>
      <NewsAndUpdates />
    </DefaultLayout>
  )
}
